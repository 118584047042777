.App {
  font-family: sans-serif;
  text-align: center;
}

.highlighted-beat {
  background-color: #facc15 !important; /* bright yellow with !important */
  transition: background-color;
}

.measure-heading {
  position: sticky;
  top: 60px; /* Adjust this so it sits below your floating Start/Reset button */
  background-color: #f9f9f9; /* or white, so it doesn't overlap text */
  z-index: 5; /* keep it above other elements while scrolling */
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;
}
